import * as R from 'ramda';
import { del, patch, post, put, get } from 'modules/api';

/**
 *
 * @returns Promise
 */
export const deleteUserPhoto = () => del('users/photos');

/**
 *
 * @param {Number|String} userId
 * @param {Object} acceptedDocuments {hasAcceptedTOS, hasAcceptedSCU}
 * @returns Promise
 */
export const updateAcceptDocument = (userId, acceptedDocuments) =>
    patch(`users/${userId}`, acceptedDocuments);

/**
 *
 * @param {Number|String} userId
 * @returns Promise
 */
export const personalDocumentsRequest = userId => post(`users/${userId}/data_recovery`);

/**
 *
 * @param {Object} data
 * @returns Promise
 */
export const register = data =>
    post('users/', { ...data, role: data.role === 'host' ? 'leader' : data.role });

/**
 *
 * @param {String} email
 * @returns Promise
 */
export const askForNewPassword = email => post(`password_reset/`, { email });

/**
 *
 * @param {Number|String} userId
 * @param {String} token
 * @param {String} password
 * @returns Promise
 */
export const resetPassword = (userId, token, password) =>
    put(`password_set/`, { userId, token, password });

/**
 *
 * @param {Number|String} userId
 * @returns Promise
 */
export const createLeaderProfile = userId =>
    post(`users/${userId}/leader_profile`, {
        blob: '{}',
    });

/**
 *
 * @param {Number|String} userId
 * @param {Object} payload
 * @returns Promise
 */
export const updateLeaderProfile = (userId, payload) =>
    patch(`users/${userId}/leader_profile`, payload);

/**
 *
 * @param {Number|String} userId
 * @param {Object} payload
 * @returns Promise
 */
export const updateUser = (userId, payload) => patch(`users/${userId}`, payload);

/**
 *
 * @returns Promise
 */
export const getUserInfo = () => get('me/');

/**
 *
 * @param {Number|String} _.userId
 * @returns Promise
 */
export const fetch10YearsData = ({ userId }) => get(`users/${userId}/ten-years-history`);

/**
 *
 * @param {Object} data
 * { basketUuid,
 *      lastName,
 *      firstName,
 *      plainPassword,
 *      email,
 *      address: {
 *          street,
 *          city,
 *      },
 *      phone,
 *      hasAcceptedTOS,
 *      birthday,
 *      nationality,
 *      accountSourceApp,
 *      accountSourcePage }
 *
 * @returns
 */
export const registerWithAnonymousBasket = data =>
    post('users/register-with-anonymous-basket', data);

/**
 *
 * @param {Number} userId
 * @returns Promise
 */
export const fetchCompaniesByUser = userId => get(`users/${userId}/companies`);

/**
 *
 * @param {Number} userId
 * @returns Promise
 */
export const fetchCompanies = userId => get(`users/${userId}/companies`);

const formatAndFilter = R.pipe(
    R.propOr([], 'persons'),
    R.reject(R.propEq('id', 2154177)) // LRQDO Support
);

/**
 *
 * @param {String} userUuid
 * @returns Promise
 */
export const initContactSuggestions = userUuid =>
    get(`users/${userUuid}/contacts`, { q: 'warmup' });

/**
 *
 * @param {String} userUuid
 * @param {String} pattern
 * @returns Promise
 */
export const getContactSuggestions = (userUuid, pattern) =>
    get(`users/${userUuid}/contacts`, { q: pattern }).then(formatAndFilter);

/**
 *
 * @param {String} userUuid
 * @param {Array} ids
 * @returns Promise
 */
export const getContactsByIds = (userUuid, ids) =>
    get(`users/${userUuid}/contacts`, { id: ids }).then(formatAndFilter);

/**
 *
 * @param {String} uuid
 * @returns Promise
 */
export const getInvoicesByUuid = uuid => get(`users/${uuid}/invoices`);

/**
 *
 * @returns Promise
 */
export const getMyInvoices = () => get('me/invoices');

/**
 *
 * @param {Number} userId
 * @param {Object} profileData
 * @returns Promise
 */
export const updateUserKyc = (userId, profileData) => post(`users/${userId}/kyc`, profileData);
